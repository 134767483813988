<template>
  <div class="footer">
     <ul class="footer-ul">
          <li v-for="(item,index) in navList" :key="index" @click="footerJump(item.path,index)"> 
                      {{item.name}}
                  </li>
     </ul>
     <div class="footer-division"></div>
     <div class="footer-bottom">
         <span>Copyright © 2021 深圳市壹蝉科技服务有限公司 版权所有 </span>
         <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021104128号-1</a>
     </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
        currentKey:0,
        navList:[
            {
                name:"首页",
                path:'/'
            },
             {
                name:"系统介绍",
                path:'/website'
            },
             {
                name:"观影卡介绍",
                path:'/movie'
            },
             {
                name:"关于我们",
                path:'/about'
            },

        ]
     
    });

    const footerJump = (val,index) =>{
        data.currentKey = index
      proxy.$router.push({
        path: val,
      });
    }

    // 页面加载时调用
    onMounted(() => {});
    return {
        footerJump,
      ...toRefs(data),
    };
  },
};
</script>
<style lang='scss' scoped>
.footer{
    width: 100%;
   // height: 249px;
    background: #373536;
    flex-direction: column;
.footer-ul{
    width: 910px;
    margin: 0px auto 50px auto;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    @media screen and (max-width: 500px) {
          width: 70%;
      }
    li{
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
        cursor: pointer;
    }
}
.footer-division{
    width: 1254px;
    margin:0 auto;
height: 0px;
opacity: 1;
border: 1px  dashed #FFFFFF;
@media screen and (max-width: 500px) {
          width: 80%;
      }
}
.footer-bottom{
    font-size: 20px;
    color: #ffffff;
    margin-top: 50px;
    padding-bottom: 50px;
    a{
       color: #ffffff; 
    }
}
}

</style>