import { createStore } from 'vuex'

export default createStore({
  state: {
    test:''
  },
  mutations: {
    setTest(state, value) {
      state.test = value
    },
  },
  actions: {
  },
  modules: {
  }
})
