import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import { storage } from '@/utils/storage'
import store from '@/store'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      indexactive:0
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      indexactive:3
    }

  },
  {
    path: '/website',
    name: 'Website',
    component: () => import('../views/Website.vue'),
    meta: {
      indexactive:1
    }
  },
  {
    path: '/movie',
    name: 'Movie',
    component: () => import('../views/Movie.vue'),
    meta: {
      indexactive:2
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//路由守卫
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  store.commit('setTest', to.meta.indexactive)
  storage.set('activeindex', to.meta.indexactive)
  next()
})
export default router
