import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { storage } from '@/utils/storage'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/js/flexible.js'
// 创建App实例
const app = createApp(App)
app.config.globalProperties.$store = store
app.config.globalProperties.$storage = storage
app.use(ElementPlus).use(store).use(router).mount('#app')

