<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <Header />
  <router-view />
  <Footer />
  <el-backtop />
</template>
<script>
import Header from "@/components/layout/header.vue";
import Footer from "@/components/layout/footer.vue";
import { onMounted, reactive, toRefs, getCurrentInstance,onBeforeMount} from "vue";
export default {
  components: {
    Header,
    Footer,
  },

  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({});
   onBeforeMount(() => {
        let url = window.location.pathname
        if(url == '/'){
            proxy.$storage.set('activeindex', 0)
           
        }
        if(url == '/website'){
            proxy.$storage.set('activeindex', 1)
        }
       
        if(url == '/movie'){
            proxy.$storage.set('activeindex', 2)
        }
         if(url == '/about'){
            proxy.$storage.set('activeindex', 3)
        }
    });
    // 页面加载时调用
    onMounted(() => {
      
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss">
body,
ul,
dl,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 400;
  font-family: Microsoft YaHei-Light, Microsoft YaHei;
}

/* 行内元素 */
a {
  text-decoration: none;
  color: #666;
}

/* 行内块元素 */
img,
input {
  border: 0 none;
  outline-style: none;
  padding: 0;
  vertical-align: top;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-carousel__indicator--horizontal .el-carousel__button {
  width: 20px;
  height: 20px;
  background: #ffffff;
  opacity: 1;
  border-radius: 50%;
  margin-right: 30px;
}
.el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 20px;
  height: 20px;
  background: #000000;
  opacity: 0.5;
  border-radius: 50%;
}
.el-carousel__indicators--horizontal {
  bottom: 40px;
}
.el-carousel__container{
  height: 774px !important;
}
.el-carousel__item img{
  width: 100%;
  height: 774px;
}
.el-carousel--horizontal{
  width: 100%;
  height: 774px;
}

// @media screen and (max-width: 1900px) {
// .el-carousel--horizontal{
//     height: 774px;
// }
// }

</style>
