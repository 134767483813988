<template>
  <div class="home">
    <div class="banner">
      <el-carousel height="774px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.url" @click="bannerJump(item.path)"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content1">
      <h3 class="content-h3">福利市场痛点</h3>
      <div class="content-text">
        <p>
          系统研发投入时间长、客户服务跟不上、人员成本高、货物量不可控，中小型企业改变单一产品经营模式，寻求业务转型和产品升级迫在眉睫。
        </p>
        <p></p>
      </div>
      <div class="card-carousel-wrapper">
        <div
          :class="[
            atHeadOfList
              ? 'card-carousel--nav__left  card-carousel--nav__pubactive'
              : 'card-carousel--nav__left',
          ]"
          @click="moveCarousel(-1)"
        ></div>
        <div class="card-carousel">
          <div class="card-carousel--overflow-container">
            <div
              class="card-carousel-cards"
              :style="{
                transform: 'translateX' + '(' + currentOffset + 'px' + ')',
              }"
            >
              <div
                class="card-carousel--card"
                v-for="item in cardList"
                :key="item.id"
              >
                <img :src="item.url" />
                <div class="card-carousel--card--footer">
                  <p>{{ item.name1 }}</p>
                  <p>{{ item.name2 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[
            atEndOfList
              ? 'card-carousel--nav__right  card-carousel--nav__pubactive'
              : 'card-carousel--nav__right',
          ]"
          @click="moveCarousel(1)"
        ></div>
      </div>
    </div>
    <div class="content2">
      <h3 class="content-h3">降本、增收、提效三板斧</h3>
      <p class="content-p">助力企业更好经营，回报更高</p>
      <ul>
        <li v-for="(item, index) in describeList" :key="index">
          <img :src="item.url" />
          <div>{{ item.name1 }}</div>
          <p>{{ item.name2 }}</p>
          <p>{{ item.name3 }}</p>
        </li>
      </ul>
    </div>
    <div class="content3">
      <h3 class="content-h3">
        一套有价值、可定制、提供一站式福利服务方案的系统
      </h3>
      <div class="content3-div1">
        <div>
          <p>壹蝉科技</p>
          <p>一站式福利服务解决方案</p>
        </div>
        <img src="@/assets/images/circleImg.png" />
      </div>
      <div class="content3-div2">
        <img src="@/assets/images/downarrow.png" />
      </div>
      <ul>
        <li v-for="(item, index) in planList" :key="index">
          <img
            class="rightarrow"
            v-if="item.arrowShow"
            src="@/assets/images/rightarrow.png"
          />
          <img class="img-pub" :src="item.url" />
          <div>{{ item.name1 }}</div>
          <p>{{ item.name2 }}</p>
        </li>
      </ul>
    </div>
    <div class="content4">
      <h3 class="content-h3">企业福利经营解决方案系统</h3>
      <div class="content4-div">
        <p>依托强大的福利平台体系，壹蝉科技为企业提供福利经营解决方案系统，为企业打造自有品牌、自有产品、助力企业快速切入福利市场、低成本创收，低风险经营，实现业务转型升级。</p>
        <p></p>
        <p></p>
      </div>
      <div class="content4-div-bt">
        <div>
          <!-- <div class="active-erect">金蝉看系统解决方案</div> -->
          <h4>壹蝉科技</h4>
          <ul>
            <li v-for="(item, index) in scienceList" :key="index">
              <img :src="item.url" />
              <p>{{ item.name1 }}</p>
            </li>
          </ul>
        </div>
        <div>
          <h4>福利营销企业</h4>
          <ul>
            <li v-for="(item, index) in welfareList" :key="index">
              <img :src="item.url" />
              <p>{{ item.name1 }}</p>
              <p>{{ item.name2 }}</p>
              <img
                v-if="index == 3"
                class="zx-icon"
                src="@/assets/images/zxIcon.png"
              />
            </li>
          </ul>
        </div>
        <div>
          <h4>福利需求客户</h4>
          <ul>
            <li v-for="(item, index) in demandList" :key="index">
              <img :src="item.url" />
              <p>{{ item.name1 }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content5">
      <h3 class="content-h3">关于我们</h3>
      <div class="product-box">
        <div>
          <img src="@/assets/images/productLogo.png" />
          <img src="@/assets/images/productName.png" />
        </div>
        <div>
          壹蝉科技成立于2021年，我们是一家科技创新服务型的科技公司，公司秉承以服务为根，改革创新为本的运营方针，重金打造国内领先的品卡券发售平台，服务于互联网最前沿的数字消费及实体产品的消费需求，为合作伙伴提供互联互通的产品定制及综合系统服务解决方案，促进数字商品及实体商品的高效流转，助力企业降本增效及提升产品和品牌价值。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance, computed } from "vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      currentOffset: 0,
      windowSize: 5,
      paginationFactor: 310,
      bannerList: [
        {
          id: 1,
          url: require("@/assets/images/banner1.png"),
          path:'/movie'
        },
        {
          id: 2,
          url: require("@/assets/images/banner2.png"),
          path:'/website'
        },
        {
          id: 3,
          url: require("@/assets/images/banner3.png"),
          path:'/website'
        },
        {
          id: 4,
          url: require("@/assets/images/banner4.png"),  
        },
      ],
      cardList: [
        {
          name1: "业务经营模式单一",
          name2: "市场竞争力有限",
          url: require("@/assets/images/cartScoll1.png"),
        },
        {
          name1: "产品单一",
          name2: "难以满足需求",
          url: require("@/assets/images/cartScoll2.png"),
        },
        {
          name1: "人员成本高",
          name2: "压缩企业盈利空间",
          url: require("@/assets/images/cartScoll3.png"),
        },
        {
          name1: "缺少全方位",
          name2: "经营系统",
          url: require("@/assets/images/cartScoll4.png"),
        },
        {
          name1: "客服质量有限",
          name2: "客户满意度低",
          url: require("@/assets/images/cartScoll5.png"),
        },
         {
          name1: "经济投入风险高",
          name2: "",
          url: require("@/assets/images/cartScoll6.png"),
        },
         {
          name1: "系统研发时间长",
          name2: "增加时间成本",
          url: require("@/assets/images/cartScoll7.png"),
        },
         {
          name1: "货物量不可控",
          name2: "",
          url: require("@/assets/images/cartScoll8.png"),
        },

      ],
      describeList: [
        {
          name1: "降本策略",
          name2: "助力企业降低运营成本",
          url: require("@/assets/images/describeImg1.png"),
        },
        {
          name1: "增收策略",
          name2: "售前、售中、售后一站式为您",
          name3: "提供专业服务",
          url: require("@/assets/images/describeImg2.png"),
        },
        {
          name1: "提效策略",
          name2: "用系统替代对人工的依赖",
          name3: "效率提升30%",
          url: require("@/assets/images/describeImg3.png"),
        },
      ],
      planList: [
        {
          name1: "项目策划",
          name2: "根据企业需求，提供系统定制、卡面设计、产品运营等策划服务",
          url: require("@/assets/images/planIcon1.png"),
          arrowShow: true,
        },
        {
          name1: "系统开发",
          name2: "开发礼品福利系统，功能包括商品价格、有效期、发放周期、次数等",
          url: require("@/assets/images/planIcon2.png"),
          arrowShow: true,
        },
        {
          name1: "产品上线",
          name2: "按企业福利礼品需求，为产品包装在商城上线",
          url: require("@/assets/images/planIcon3.png"),
          arrowShow: true,
        },
        {
          name1: "礼品兑换",
          name2: "客户通过金蝉看小程序输入卡券的账号密码激活后，即可礼品兑换",
          url: require("@/assets/images/planIcon4.png"),
          arrowShow: true,
        },
        {
          name1: "售后服务",
          name2:
            "壹蝉科技提供专属客服，为客户提供售前、售中、售后的客服服务，让企业无后顾之忧",
          url: require("@/assets/images/planIcon5.png"),
          arrowShow: true,
        },
        {
          name1: "礼品核销",
          name2: "对于发放的礼品卡券已提货进行核销",
          url: require("@/assets/images/planIcon6.png"),
          arrowShow: false,
        },
      ],
      scienceList: [
        {
          name1: "商品上线",
          url: require("@/assets/images/scienceIcon1.png"),
        },
        {
          name1: "一站式客服服务",
          url: require("@/assets/images/scienceIcon2.png"),
        },
        {
          name1: "权益设计管理",
          url: require("@/assets/images/scienceIcon3.png"),
        },
        {
          name1: "礼品核销",
          url: require("@/assets/images/scienceIcon4.png"),
        },
      ],
      welfareList: [
        {
          name1: "自有福利商城",
          name2: "及经营系统",
          url: require("@/assets/images/welfareIcon1.png"),
        },
        {
          name1: "自有供应链及",
          name2: "商品资源",
          url: require("@/assets/images/welfareIcon2.png"),
        },
        {
          name1: "自有品牌及",
          name2: "客户资源",
          url: require("@/assets/images/welfareIcon3.png"),
        },
        {
          url: require("@/assets/images/welfareIcon4.png"),
        },
      ],
      demandList: [
        {
          name1: "电影福利",
          url: require("@/assets/images/demandIcon1.png"),
        },
        {
          name1: "生日福利",
          url: require("@/assets/images/demandIcon2.png"),
        },
        {
          name1: "年节礼包",
          url: require("@/assets/images/demandIcon3.png"),
        },
        {
          name1: "通兑福利",
          url: require("@/assets/images/demandIcon4.png"),
        },
      ],
    });
    //banner跳转
    const bannerJump = (val)=>{
      if(val){
        proxy.$router.push({
        path: val,
      });
      }
        
    };

    let atEndOfList = computed(() => {
      return (
        data.currentOffset <=
        data.paginationFactor * -1 * (data.cardList.length - data.windowSize)
      );
    });
    let atHeadOfList = computed(() => {
      return data.currentOffset === 0;
    });
    const navJump = (val, index) => {
      data.currentKey = index;
      proxy.$router.push({
        path: val,
      });
    };
    const moveCarousel = (direction) => {
      console.log(atEndOfList.value);
      if (direction === 1 && !atEndOfList.value) {
        data.currentOffset -= data.paginationFactor;
        console.log(data.currentOffset);
      } else if (direction === -1 && !atHeadOfList.value) {
        data.currentOffset += data.paginationFactor;
      }
    };


    // 页面加载时调用
    onMounted(() => {});
    return {
      navJump,
      moveCarousel,
      atHeadOfList,
      atEndOfList,
      bannerJump,
      ...toRefs(data),
    };
  },
};
</script>

<style lang='scss' scoped>
$primary-color: #2b2b2b;
$primary-size: 50px;
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  .banner {
    // height: 774px;
   :deep .el-carousel__arrow{
  height: 60px;
  width: 60px;
  .el-icon{
  height: 40px;
  width: 40px;
  svg{
    height: 40px;
     width: 40px;
  }
}
}

  }
  .content1 {
    background: #efefef;
    padding-top: 42px;
    padding-bottom: 67px;
    .content-h3 {
      color: $primary-color;
      font-size: $primary-size;
      position: relative;
      &::after {
        content: "";
        width: 237px;
        height: 6px;
        background: #808080;
        position: absolute;
        left: 50%;
        margin-left: -118.5px;
        bottom: -30px;
      }
    }

    .content-text {
      margin-top: 64px;
      font-size: 30px;
      color: #505050;
      letter-spacing: 10px;
      p {
        width: 68%;
        margin: 0 auto;
        text-align: left;
        line-height: 52px;
        &:first-of-type {
          margin-bottom: 12px;
        }
      }
    }
    .card-carousel-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0 40px;
      color: #666a73;
      width: 1720px;
      margin: 60px auto 0px auto;
    }

    .card-carousel {
      display: flex;
      justify-content: center;
      width: 1530px;
    }
    .card-carousel--overflow-container {
      overflow: hidden;
    }
    .card-carousel--nav__left,
    .card-carousel--nav__right {
      display: inline-block;
      width: 47px;
      height: 47px;
      padding: 10px;
      box-sizing: border-box;
      border-top: 3px solid #42b883;
      border-right: 3px solid #42b883;
      cursor: pointer;
      margin: 0 10px;
      transition: transform 150ms linear;
      margin-top: -98px;
    }
    .card-carousel--nav__pubactive {
      opacity: 0.2;
      border-color: #888888;
    }
    .card-carousel--nav__left {
      transform: rotate(-135deg);
    }
    .card-carousel--nav__left:active {
      transform: rotate(-135deg) scale(0.9);
    }
    .card-carousel--nav__right {
      transform: rotate(45deg);
    }
    .card-carousel--nav__right:active {
      transform: rotate(45deg) scale(0.9);
    }

    .card-carousel-cards {
      display: flex;
      transition: transform 150ms ease-out;
      transform: translatex(0px);
    }
    .card-carousel-cards .card-carousel--card {
      margin: 0 10px;
      border-radius: 4px;
      z-index: 3;
      margin-bottom: 2px;
    }
    .card-carousel-cards .card-carousel--card:first-child {
      margin-left: 0;
    }
    .card-carousel-cards .card-carousel--card:last-child {
      margin-right: 0;
    }
    .card-carousel-cards .card-carousel--card img {
      vertical-align: bottom;
      // transition: opacity 150ms linear;
      user-select: none;
      width: 290px;
      height: 290px;
      transition: all 0.4s;
    }
    .card-carousel-cards .card-carousel--card img:hover {
      opacity: 0.8;
      transform: scale(1.05);
    }
    .card-carousel-cards .card-carousel--card--footer {
      border-top: 0;
      padding-top: 20px;
      p {
        padding: 1px 0;
        margin: 0;
        margin-bottom: 2px;
        font-size: 26px;
        color: #505050;
      }
    }
  }
  .content2 {
    padding: 60px 0px;
    .content-h3 {
      color: $primary-color;
      font-size: $primary-size;
      position: relative;
      &::after {
        content: "";
        width: 237px;
        height: 6px;
        background: #808080;
        position: absolute;
        left: 50%;
        margin-left: -118.5px;
        bottom: -75px;
      }
    }
    .content-p {
      font-size: 32px;
      color: $primary-color;
      margin-top: 10px;
      margin-bottom: 93px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      width: 1590px;
      margin: 0 auto;
      li {
        text-align: left;
        font-size: 26px;
        img {
          width: 490px;
          height: 314px;
        }
        div {
          color: #2b2b2b;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        p {
          color: #b5b5b5;
        }
      }
    }
  }
  .content3 {
    background: #efefef;
    padding: 60px 0;
    justify-content: center;
    flex-direction: column;
    display: flex;
    .content-h3 {
      color: $primary-color;
      font-size: $primary-size;
      position: relative;
      margin-bottom: 42px;
    }
    .content3-div1 {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        font-size: 32px;
        color: #2b2b2b;
        margin-top: 116px;
        p {
          &:first-of-type {
            position: relative;
            margin-bottom: 30px;
            &::after {
              content: "";
              position: absolute;
              width: 154px;
              height: 1px;

              background-image: linear-gradient(
                to right,
                #707070 0%,
                #707070 50%,
                transparent 0%
              );
              background-size: 11px 1px;
              background-repeat: repeat-x;
              left: 50%;
              margin-left: -77px;
              bottom: -13px;
            }
          }
        }
      }
      img {
        width: 380px;
        height: 380px;
      }
    }
    .content3-div2 {
      margin-top: 21px;
      margin-bottom: 40px;
      img {
        width: 58px;
        height: 70px;
      }
    }
    ul {
      display: flex;
      width: 1718px;
      justify-content: space-between;
      margin: 0 auto;
      li {
        position: relative;
        width: 264px;
        &:last-of-type {
          p {
            // width: 220px;
          }
        }
        &:nth-of-type(3) {
          p {
            width: 242px;
          }
        }
        .rightarrow {
          position: absolute;
          top: 36px;
          width: 70px;
          height: 58px;
          right: -49.5px;
        }
        .img-pub {
          width: 130px;
          height: 130px;
        }
        div {
          color: #2b2b2b;
          font-size: 34px;
          margin-top: 28px;
          margin-bottom: 20px;
        }
        p {
          font-size: 22px;
          color: #2b2b2b;
          font-weight: 300;
          text-align: left;
        }
      }
    }
  }
  .content4 {
    padding: 60px 0px;
    .content-h3 {
      color: $primary-color;
      font-size: $primary-size;
      position: relative;
      margin-bottom: 44px;
      &::after {
        content: "";
        width: 237px;
        height: 6px;
        background: #808080;
        position: absolute;
        left: 50%;
        margin-left: -118.5px;
        bottom: -13px;
      }
    }
    .content4-div {
      color: #2b2b2b;
      font-size: 30px;
      margin-bottom: 89px;
      p {
        margin-top: 10px;
        letter-spacing: 10px;
        width: 68%;
        margin: 0 auto;
        text-align:left;
        line-height: 52px;
      }
    }
    .content4-div-bt {
      display: flex;
      width: 1515px;
      margin: auto;
      justify-content: space-between;
      div {
        &:first-of-type {
          position: relative;
           .active-erect{
            writing-mode: tb-rl;
            font-size: 30px;
            color: #508E53;
            position: absolute;
            left: -83px;
            bottom: 50px;
          }
          h4 {
            font-size: 30px;
            color: #2b2b2b;
            margin-bottom: 24px;
          }
          ul {
            display: flex;
            width: 385px;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            li {
              width: calc(calc(100% / 2) - 38px);
              box-sizing: border-box;
              &:first-of-type {
                img {
                  width: 140px;
                  height: 140px;
                }
              }
              &:nth-of-type(2) {
                img {
                  width: 100px;
                  height: 100px;
                  margin-bottom: 26px;
                }
              }
              &:nth-of-type(3) {
                img {
                  width: 100px;
                  height: 100px;
                  margin-bottom: 29px;
                  margin-top: 35px;
                }
              }
              &:nth-of-type(4) {
                img {
                  width: 120px;
                  height: 120px;
                  margin-bottom: 17px;
                  margin-top: 24px;
                }
              }
              p {
                color: #2b2b2b;
                font-size: 22px;
                font-weight: 300;
              }
            }
          }
        }
        &:nth-of-type(2) {
          h4 {
            font-size: 30px;
            color: #2b2b2b;
            margin-bottom: 42px;
          }
          ul {
            display: flex;
            width: 526px;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            li {
              width: calc(calc(100% / 3) - 35px);
              box-sizing: border-box;
              &:first-of-type {
                img {
                  width: 110px;
                  height: 110px;
                  margin-bottom: 26px;
                }
              }
              &:nth-of-type(2) {
                img {
                  width: 156px;
                  height: 93px;
                  margin-bottom: 42px;
                }
              }
              &:nth-of-type(3) {
                img {
                  width: 103px;
                  height: 103px;
                  margin-bottom: 29px;
                }
              }
              &:nth-of-type(4) {
                width: 100%;
                img {
                  width: 153px;
                  height: 100px;
                  margin-bottom: 17px;
                  margin-top: 41px;
                  &.zx-icon {
                    width: 407px;
                    height: 92px;
                    position: relative;
                    margin-top: -166px;
                  }
                }
              }
              p {
                color: #2b2b2b;
                font-size: 22px;
                font-weight: 300;
                &:first-of-type {
                  margin-bottom: 4px;
                }
              }
            }
          }
        }
        &:last-of-type {
         
          h4 {
            font-size: 30px;
            color: #2b2b2b;
            margin-bottom: 29px;
          }
          ul {
            display: flex;
            width: 286px;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            li {
              width: calc(calc(100% / 2) - 28px);
              box-sizing: border-box;
              &:first-of-type {
                img {
                  width: 110px;
                  height: 110px;
                  margin-bottom: 34px;
                  position: relative;
                  top:8px
                }
              }
              &:nth-of-type(2) {
                img {
                  width: 120px;
                  height: 120px;
                  margin-bottom: 23px;
                }
              }
              &:nth-of-type(3) {
                img {
                  width: 110px;
                  height: 110px;
                  margin-bottom: 19px;
                  margin-top: 21px;
                }
              }
              &:nth-of-type(4) {
                img {
                  width: 100px;
                  height: 100px;
                  margin-bottom: 21px;
                  margin-top: 29px;
                }
              }
              p {
                color: #2b2b2b;
                font-size: 22px;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }
  .content5 {
    padding: 60px 0px 0px 0px;
    .content-h3 {
      color: $primary-color;
      font-size: $primary-size;
      position: relative;
      margin-bottom: 95px;
      &::after {
        content: "";
        width: 237px;
        height: 6px;
        background: #808080;
        position: absolute;
        left: 50%;
        margin-left: -118.5px;
        bottom: -18px;
      }
    }
    .product-box {
      width: 100%;
      height: 569px;
      background: url(../assets/images/productBg.png) no-repeat;
      background-size:100% 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding-left: 265px;
      padding-right: 257px;
      align-items: center;
      div{
        &:first-of-type{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items:center;
          img{
            &:first-of-type{
              width: 223px;
              // height: 59px;
              margin-bottom: 10px;
            }
            &:last-of-type{
              width: 227px;
              height: 59px;
            }
          }
        }
        &:last-of-type{
          font-size: 28px;
          color: #ffffff;
          width: 674px;
          box-sizing: border-box;
          // padding-right: 36px;
          line-height: 48px;
          letter-spacing: 3px;
          text-align: left;
          text-indent: 62px;
          font-weight: 400;
          padding-left: 20px;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            width: 2px;
height: 244px;
background: #E8E9E9;
left: -200px;
top: 60px;
          }

        }
      }
    }
  }
}

</style>
