<template>
  <div class="header">
      <div class="header-box">
          <div class="logo">
              <img src="@/assets/images/logo.png"/>
          </div>
          <div class="nav">
              <ul>
                  <li v-for="(item,index) in navList" :class="{'active':store.state.test==index}" :key="index" @click="navJump(item.path,index)"> 
                      {{item.name}}
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
import store from '@/store'
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
        currentKey:proxy.$storage.get('activeindex')||0,
        test:store.state.test,
        navList:[
            {
                name:"首页",
                path:'/'
            },
             {
                name:"系统介绍",
                path:'/website'
            },
             {
                name:"观影卡介绍",
                path:'/movie'
            },
             {
                name:"关于我们",
                path:'/about'
            },

        ]
     
    });

    const navJump = (val,index) =>{
        data.currentKey = index
      proxy.$router.push({
        path: val,
      });
    }
    // 页面加载时调用
    onMounted(() => {
       console.log(proxy.$store.state,proxy.$storage.get('activeindex'),store.state.test) 
    });
    return {
        navJump,
        store,
      ...toRefs(data),
    };
  },
};
</script>

<style lang='scss' scoped>
.header{
    width: 100%;
    height: 110px;
    line-height: 110px;
    background: #ffffff;
    box-sizing:border-box;
  .header-box{
      width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 500px) {
          width: 86%;
      }
      .logo{
          width: 232px;
          display: flex;
        //  flex-direction: column;
        //   height: 40px;
          margin-right: 224px;
          img{
              width: 100%;
          }
          span{
              line-height: 20px;
              font-size: 17px;
              letter-spacing: 8.5px;
              text-indent: 4px;
          }
      }
      .nav{
          flex: 1;
          ul{
              display: flex;
              li{
                  flex: 1;
                  font-size: 30px;
                  position: relative;
                  cursor: pointer;
                  &.active::after{
                      content: '';
                      position: absolute;
                      height: 6px;
                      background: #2B2B2B;
                      width: 200px;
                      left: 50%;
                      bottom: 0;
                      margin-left: -100px;
                      
}
              }
          }

      }
  }  
}

</style>